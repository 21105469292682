import { DisplayColumnDef } from '@tanstack/react-table';
import { Column } from './types';

export const TABLE_NAME = 'my-files';

export const getMyContentColumns = (): Record<string, DisplayColumnDef<Column>> => ({
  id: {
    id: 'id',
    header: 'Content ID',
    size: 100,
  },
  type: {
    id: 'type',
    header: 'Type',
    size: 50,
  },
  name: {
    id: 'name',
    header: 'Name',
    size: 100,
  },
  createdAt: {
    id: 'createdAt',
    header: 'Date Uploaded',
    size: 50,
  },
  size: {
    id: 'size',
    header: 'Size',
    size: 50,
  },
  storage: {
    id: 'storage',
    header: 'Storage',
    size: 50,
  },
  offerId: {
    id: 'offerId',
    header: 'Offer Id',
    size: 50,
  },
  edit: {
    id: 'edit',
    header: '',
    size: 66,
    minSize: 66,
    meta: {
      show: true,
      ellipsis: false,
    },
  },
});
