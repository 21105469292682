'use client';

import {
  memo, FC, useMemo, useEffect, useRef,
} from 'react';
import mergeWith from 'lodash.mergewith';
import { Provider } from 'react-redux';
import { AppStore, createStoreClient, storages } from 'lib/store';
import { HYDRATE_STORE } from 'lib/features/helpers';
import { Storages } from 'lib/features/types';
import { PersistGateProps } from './types';

export const PersistGate: FC<PersistGateProps> = memo(({
  children, serverState, hydrate, store,
}) => {
  const filteredState = useMemo(() => {
    if (!serverState) return {};
    return Object
      .entries(storages)
      .reduce((acc, [reducerName, storage]) => {
        // restore only cookie storage from server. other storages hydrate on the client
        if (storage === Storages.cookie && serverState[reducerName]) {
          return { ...acc, [reducerName]: serverState[reducerName] };
        }
        return acc;
      }, {});
  }, [serverState]);

  // merge server state with new hydrated state
  const preloadedState = useMemo(() => mergeWith(filteredState, hydrate), [filteredState, hydrate]);

  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = createStoreClient({ preloadedState });
  }

  // const store = useMemo(() => createStoreClient({ preloadedState }), [preloadedState]);

  useEffect(() => {
    if (hydrate) {
      store.dispatch({ type: HYDRATE_STORE, payload: hydrate });
    }
  }, [hydrate, store]);

  return (
    <Provider store={storeRef.current}>
      {children}
    </Provider>
  );
});