import { useCallback, useRef, useEffect } from 'react';
import { BigNumber } from 'bignumber.js';
import { useDebouncedCallback } from 'use-debounce';
import { updateBalance } from 'lib/features/wallet/thunks';
import { selectedAddressSelector, selectedWalletTypeSelector } from 'lib/features/wallet';
import { useLazyBalanceOfQuery, useLazyTeeBalanceOfQuery } from 'generated/schemas/common';
import BlockchainEventConnector from 'connectors/sdk/BlockchainEventConnector';
import { WalletType } from 'types/wallet';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import useSyncProviders from './useSyncProviders';

export interface WalletInfo {
  chainId?: number | null;
  accounts?: string[];
}

export interface Wallet {
  [WalletType.MetaMask]?: WalletInfo;
}

export type SelectedWalletType = WalletType | null;
export interface Balance { matic?: BigNumber, tee?: BigNumber }

const INTERVAL = 100;

const parseBalanceResult = async (promise: Promise<{data?: { result?: string }}>) => {
  try {
    const { data } = await promise;
    return (typeof data?.result === 'string' ? data?.result || '0' : undefined);
  } catch {
    return undefined;
  }
};

export interface UseWalletInitializerProps {
  skip?: boolean;
  connectWallet: (walletType: WalletType) => void;
}

const useWalletInitializer = ({ skip = true, connectWallet }: UseWalletInitializerProps) => {
  useSyncProviders(); // pre sync providers
  const subscription = useRef<() => void>();
  const debounceUpdateBalanceSubscription = useRef<ReturnType<typeof debounceUpdateBalance>>();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const dispatch = useAppDispatch();
  const selectedWalletType = useAppSelector(selectedWalletTypeSelector);
  const [getBalanceOf] = useLazyBalanceOfQuery();
  const [getTeeBalanceOf] = useLazyTeeBalanceOfQuery();

  const getBalance = useCallback(async (address: string): Promise<{ matic?: string; tee?: string }> => {
    return {
      matic: await parseBalanceResult(getBalanceOf({ address })),
      tee: await parseBalanceResult(getTeeBalanceOf({ address })),
    };
  }, [getBalanceOf, getTeeBalanceOf]);

  const subscribeChangeBalance = useCallback(async (cb: (owner: string, spender: string) => void) => {
    return BlockchainEventConnector.getInstance().onTokenTransfer(cb);
  }, []);

  const updateBalanceDispatch = useCallback(() => dispatch(updateBalance({ getBalance })), [dispatch, getBalance]);
  const debounceUpdateBalance = useDebouncedCallback(updateBalanceDispatch, INTERVAL);

  const subscribeChangeBalanceCb = useCallback((owner: string, spender: string) => {
    if (selectedAddress && [owner, spender].includes(selectedAddress)) {
      debounceUpdateBalanceSubscription.current = debounceUpdateBalance();
    }
  }, [selectedAddress, debounceUpdateBalance]);

  useEffect(() => {
    if (!skip && selectedWalletType && connectWallet) {
      connectWallet(selectedWalletType);
    }
  }, [skip, selectedWalletType, connectWallet]);

  const initSubscriptions = useCallback(async () => {
    if (selectedAddress) {
      debounceUpdateBalanceSubscription.current?.abort?.();
      debounceUpdateBalanceSubscription.current = debounceUpdateBalance();
      subscription.current = await subscribeChangeBalance(subscribeChangeBalanceCb);
    }
  }, [subscribeChangeBalance, selectedAddress, debounceUpdateBalance, subscribeChangeBalanceCb]);

  useEffect(() => {
    initSubscriptions();
    return () => {
      subscription.current?.();
      debounceUpdateBalanceSubscription.current?.abort?.();
    };
  }, [initSubscriptions]);
};

export default useWalletInitializer;