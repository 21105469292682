// export const api = async (input: RequestInfo | URL, init?: RequestInit) => fetch(
//   input,
//   {
//     ...init,
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   },
// );

// export const checkSite = (url: string) => {
//   return api(`/api/check-site?url=${encodeURIComponent(url)}`);
// };

import {
  fetchBaseQuery,
  BaseQueryApi,
} from '@reduxjs/toolkit/query';
import { getAuthorizationHeader } from 'utils';

export const prepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
  const { getState } = api;
  const { accessToken } = (getState() as any).secretKeeper || {};
  if (accessToken) {
    headers.set('Authorization', getAuthorizationHeader(accessToken));
  }
  return headers;
};

const baseUrl = '/api';

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders,
});