import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { getBroatcastActions } from 'lib/features/helpers';
import { AlertsState, AddAlertProps, Notifications } from './types';
import { Storage } from '../types';

const initialState: AlertsState = {
  alerts: [],
  alertsChecker: {},
};

export const alerts = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert(state, action: PayloadAction<AddAlertProps>) {
      const {
        text = '', id, mode = 'default',
      } = action.payload;
      const oldAlertIndex = state.alerts.findIndex((alert) => alert.id === id);
      const newAlert = {
        text, id, mode,
      };
      if (oldAlertIndex !== -1) {
        state.alerts = [
          ...state.alerts.slice(0, oldAlertIndex),
          newAlert,
          ...state.alerts.slice(oldAlertIndex + 1, state.alerts.length),
        ];
      } else {
        state.alerts = [...state.alerts, newAlert];
      }
    },
    removeAlert(state, action: PayloadAction<{ id: Notifications }>) {
      const { id } = action.payload;
      state.alerts = state.alerts.filter((item) => item.id !== id);
    },
    addAlertChecker(state, action: PayloadAction<{ id: Notifications, data?: any }>) {
      const { id, data } = action.payload;
      state.alertsChecker[id] = data || [];
    },
  },
  selectors: {
    alertsAlertsSelector: (state) => state.alerts,
    alertsChekerSelector: (state) => state.alertsChecker,
  },
});

export const {
  addAlert,
  removeAlert,
  addAlertChecker,
} = alerts.actions;

export const {
  alertsAlertsSelector,
  alertsChekerSelector,
} = alerts.selectors;

export const getBroatcastWhiteList = () => getBroatcastActions(alerts);

export const getReducer = (storage: Storage) => persistReducer({
  key: 'alerts', storage, whitelist: ['alertsChecker'],
}, alerts.reducer);
