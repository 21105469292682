import { createSlice } from '@reduxjs/toolkit';
import { EventsState } from './types';

const initialState: EventsState = {};

export const events = createSlice({
  name: 'events',
  initialState,
  reducers: {},
});
