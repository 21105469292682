import { useAppSelector } from 'lib/hooks';
import { useUpdateMeQuery, useUpdateProvidersQuery } from 'lib/features/authOAuth2/api';
import {
  accessTokenSelector,
} from 'lib/features/authOAuth2';

export const useAuthOAuth2Initializer = (skip?: boolean) => {
  const accessToken = useAppSelector(accessTokenSelector);
  const userResponse = useUpdateMeQuery(null, { skip: !accessToken || skip });
  const hasUser = !!userResponse?.data;
  useUpdateProvidersQuery(null, { skip: !hasUser || skip });
};