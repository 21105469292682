import { getBaseQuery } from 'connectors/sp';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ProvidersQuery, ProvidersQueryVariables, ProvidersDocument } from 'generated/schemas/providers';

export const providers = createApi({
  reducerPath: 'providers',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getProviders: build.query<ProvidersQuery, ProvidersQueryVariables>({
      query: (variables) => ({ document: ProvidersDocument, variables }),
    }),
  }),
});

export const { useGetProvidersQuery, useLazyGetProvidersQuery } = providers;
