
'use client';

import { runMockWorker } from 'mocks/server';
import { useEffect, useState } from 'react';
import getConfig from 'config';

const { NEXT_PUBLIC_MOCK_HTTP } = getConfig();

const isNeedMock = process.env.NODE_ENV === 'development' && NEXT_PUBLIC_MOCK_HTTP;

export const useMockHTTP = () => {
  const [waitUntilMock, setWaitUntilMock] = useState(isNeedMock ? true : null);
  useEffect(() => {
    if (isNeedMock) {
      runMockWorker().then(() => setWaitUntilMock(null));
    }    
  }, []);
  return waitUntilMock;
};