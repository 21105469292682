import * as Types from '../types';

import { api } from '../../connectors/sp';
export type PageDataDtoFragmentFragment = { __typename?: 'PageDataDto', count: number, limit: number, offset: number };

export type BalanceOfQueryVariables = Types.Exact<{
  address: Types.Scalars['String']['input'];
}>;


export type BalanceOfQuery = { __typename?: 'Query', result: string };

export type TeeBalanceOfQueryVariables = Types.Exact<{
  address: Types.Scalars['String']['input'];
}>;


export type TeeBalanceOfQuery = { __typename?: 'Query', result: string };

export const PageDataDtoFragmentFragmentDoc = `
    fragment PageDataDtoFragment on PageDataDto {
  count
  limit
  offset
}
    `;
export const BalanceOfDocument = `
    query BalanceOf($address: String!) {
  result: balanceOf(address: $address)
}
    `;
export const TeeBalanceOfDocument = `
    query TeeBalanceOf($address: String!) {
  result: teeBalanceOf(address: $address)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    BalanceOf: build.query<BalanceOfQuery, BalanceOfQueryVariables>({
      query: (variables) => ({ document: BalanceOfDocument, variables })
    }),
    TeeBalanceOf: build.query<TeeBalanceOfQuery, TeeBalanceOfQueryVariables>({
      query: (variables) => ({ document: TeeBalanceOfDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useBalanceOfQuery, useLazyBalanceOfQuery, useTeeBalanceOfQuery, useLazyTeeBalanceOfQuery } = injectedRtkApi;

