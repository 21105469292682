export const TABLE_NAME = 'events';

export const getColumns = () => ({
  _id: {
    id: '_id',
    header: '',
    size: 0,
    meta: {
      show: false,
    },
  },
  timestamp: {
    id: 'timestamp',
    header: 'Date',
    size: 150,
  },
  orderId: {
    id: 'orderId',
    header: 'Order ID',
    size: 90,
  },
  orderType: {
    id: 'orderType',
    header: 'Order Type',
    size: 90,
  },
  type: {
    id: 'type',
    header: 'Type',
    size: 90,
  },
  provider: {
    id: 'provider',
    header: 'Provider',
    size: 140,
  },
  name: {
    id: 'name',
    header: 'Name',
    size: 150,
  },
  status: {
    id: 'status',
    header: 'Status',
    size: 90,
  },
  event: {
    id: 'event',
    header: 'Event',
    size: 150,
  },
  value: {
    id: 'value',
    header: 'Value',
    size: 90,
  },
  hash: {
    id: 'transactionhash',
    header: 'Tnx Hash',
    size: 250,
  },
});

export const tableState = {
  columnVisibility: { _id: false },
};
