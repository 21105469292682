import { createMigrate } from 'redux-persist';
import { getInitialState } from './helpers';

const migrations = {
  0: (state) => ({
    ...state,
    ...getInitialState(),
  }),
  1: (state) => ({
    ...state,
    ...getInitialState(),
  })
};

export default createMigrate(migrations);
