export const TABLE_NAME = 'tunnel-orders';

export const getColumns = () => ({
  createdDate: {
    id: 'createdDate',
    header: 'Date Created',
    size: 80,
  },
  modifiedDate: {
    id: 'modifiedDate',
    header: 'Date Modified',
    size: 80,
  },
  id: {
    id: 'id',
    header: 'Order ID',
    size: 60,
  },
  name: {
    id: 'name',
    header: 'Offer',
    size: 120,
  },
  status: {
    id: 'status',
    header: 'Status',
    size: 70,
  },
  cost: {
    id: 'cost',
    header: 'Cost to Date',
    size: 80,
  },
  balance: {
    id: 'balance',
    header: 'Balance',
    size: 70,
  },
  model: {
    id: 'model',
    header: 'Model',
    size: 100,
  },
  dataset: {
    id: 'dataset',
    header: 'Dataset',
    size: 100,
  },
});

export const getSkeletonData = (num: number) => Array.from(Array(num).keys());
