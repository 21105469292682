import { onTokenApproveCallback } from '@super-protocol/sdk-js';
import getConfig from 'config';

export default class BlockchainEventConnector {
  private static instance: BlockchainEventConnector;
  private initialized = false;

  private constructor() {}

  public static getInstance(): BlockchainEventConnector {
    if (!BlockchainEventConnector.instance) {
      BlockchainEventConnector.instance = new BlockchainEventConnector();
    }

    return BlockchainEventConnector.instance;
  }

  public isInitialized() {
    return this.initialized;
  }

  public checkIfInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        `${this.constructor.name} is not initialized, needs to run '${this.constructor.name}.initialize(CONFIG)' first`,
      );
    }
  }

  private async initialize(): Promise<BlockchainEventConnector> {
    if (this.isInitialized()) return BlockchainEventConnector.getInstance();
    const {
      NEXT_PUBLIC_BLOCKCHAIN_WSS_API,
      NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
    } = getConfig();
    if (!NEXT_PUBLIC_BLOCKCHAIN_WSS_API) {
      throw new Error('BlockchainUrlWss is node defined');
    }
    if (!NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS) {
      throw new Error('ContractAddress is undefined');
    }
    const { BlockchainEventsListener } = (await import('@super-protocol/sdk-js'));
    const instance = BlockchainEventsListener.getInstance();
    await instance.initialize({
      blockchainUrl: NEXT_PUBLIC_BLOCKCHAIN_WSS_API,
      contractAddress: NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
    });
    this.initialized = true;
    return BlockchainEventConnector.getInstance();
  }

  public async shutdown(): Promise<void> {
    if (this.initialized) {
      this.initialized = false;
      const { BlockchainEventsListener } = (await import('@super-protocol/sdk-js'));
      BlockchainEventsListener.getInstance().shutdown();
    }
  }

  public async onTokenTransfer(callback: onTokenApproveCallback, owner?: string, spender?: string) {
    await this.initialize();
    const { SuperproToken } = (await import('@super-protocol/sdk-js'));
    return SuperproToken.onTokenTransfer(callback, owner, spender);
  }
}