import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getBroatcastActions } from 'lib/features/helpers';
import { SortingMethods, OrdersSortingMethodsState } from './types';
import { Storage } from '../types';
import { getInitialStateMethods } from './helpers';
import migrations from './migrations';

const initialState: OrdersSortingMethodsState = getInitialStateMethods();

const NAME = 'ordersSortingMethods';

export const ordersSortingMethods = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updateSortingMethod(state, action: PayloadAction<SortingMethods>) {
      state.sortingMethod = action.payload;
    },
  },
  selectors: {
    ordersSortingMethodsSelector: (state) => state.sortingMethod,
  },
});

export const { updateSortingMethod } = ordersSortingMethods.actions;

export const { ordersSortingMethodsSelector } = ordersSortingMethods.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: NAME, storage, whitelist: ['sortingMethod'], migrate: migrations, version: 0,
}, ordersSortingMethods.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(ordersSortingMethods);
