import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { getBroatcastActions } from 'lib/features/helpers';
import { v1 as uuid } from 'uuid';
import { Storage } from '../types';
import { SystemState } from './types';

const initialState: SystemState = {
  deviceId: uuid(),
};

export const system = createSlice({
  name: 'system',
  initialState,
  reducers: {
    updateDeviceId(state, action: PayloadAction<string>) {
      state.deviceId = action.payload;
    },
  },
  selectors: {
    deviceIdSelector: (state) => state.deviceId,
  },
});

export const {
  updateDeviceId,
} = system.actions;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'system', storage, whitelist: ['deviceId'],
}, system.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(system);