import { createApi } from '@reduxjs/toolkit/query/react';

import { getBaseQuery } from 'connectors/sp';
import {
  EventsQuery,
  EventsQueryVariables,
  EventsDocument,
} from 'generated/schemas/events';

export const eventsLog = createApi({
  reducerPath: 'eventsLog',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getEvents: build.query<EventsQuery, EventsQueryVariables>({
      query: (variables) => ({ document: EventsDocument, variables }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useLazyGetEventsQuery,
} = eventsLog;
