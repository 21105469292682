import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import { trackEvent } from 'lib/features/events/thunks';
import { useRouter, useSearchParams } from 'next/navigation';
import { parseJwt } from 'utils';
import { useAppDispatch } from 'lib/hooks';
import { validateRTKResponse } from 'lib/features/helpers';
import {
  setAccessToken as setAccessTokenSecretKeeper, resetState as resetStateSecretKeeper,
} from 'lib/features/secretKeeper';
import {
  setAccessToken as setAccessTokenOAuth2, resetState as resetStateOAuth2,
} from 'lib/features/authOAuth2';
import { AccessToken as AccessTokenSecretKeeper, Providers as ProvidersSecretKeeper } from 'lib/features/secretKeeper/types';
import { AccessToken as AccessTokenOAuth2, Providers as ProvidersOAuth2 } from 'lib/features/authOAuth2/types';
import { useAuthSPProviderInitialzer } from './useAuthSPProviderInitialzer';
import { useAuthSecretKeeperInitializer } from './useAuthSecretKeeperInitializer';
import { useAuth } from './useAuth';

const ACCESS_TOKEN_KEY = 'access_token';

export interface UpdateTokenByAuthTypeResult {
  token: string | null | undefined;
  provider?: ProvidersOAuth2 | ProvidersSecretKeeper;
  userId?: string;
}

export interface SendMetricsProps extends Omit<UpdateTokenByAuthTypeResult, 'token'> {}

export const useAuthInitializer = () => {
  const [skip, setSkip] = useState(true);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const accessTokenFromQuery = searchParams.get(ACCESS_TOKEN_KEY);
  const { getIsNewProviderSPProvider, getIsNewProviderSecretKeeper, updateCurrentUserByProvider } = useAuth();

  const updateTokenByAuthType = useCallback((token: string): UpdateTokenByAuthTypeResult => {
    dispatch(resetStateSecretKeeper());
    dispatch(resetStateOAuth2());
    const parsedToken = parseJwt<AccessTokenOAuth2 | AccessTokenSecretKeeper>(token);
    const newToken = token && typeof token === 'string' ? token : null;
    const { provider: newProvider, id: userId } = parsedToken || {};
    if (getIsNewProviderSPProvider(newProvider)) {
      dispatch(setAccessTokenOAuth2(newToken));
    }
    if (getIsNewProviderSecretKeeper(newProvider)) {
      dispatch(setAccessTokenSecretKeeper(newToken));
    }
    return {
      token: newToken,
      provider: newProvider,
      userId,
    };
  }, [dispatch, getIsNewProviderSPProvider, getIsNewProviderSecretKeeper]);

  const sendMetrics = useCallback(async (props: SendMetricsProps) => {
    try {
      validateRTKResponse(await updateCurrentUserByProvider(props.provider)(null));
      dispatch(trackEvent({
        eventType: 'user_authorized',
        property: { result: 'success', ...props },
      }));
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'user_authorized',
        property: {
          result: 'error',
          ...props,
          error: (e as Error)?.message,
          errorStack: (e as Error)?.stack,
        },
      }));
    }
  }, [dispatch, updateCurrentUserByProvider]);

  const updateToken = useCallback(async () => {
    if (accessTokenFromQuery) {
      const { provider, userId } = updateTokenByAuthType(accessTokenFromQuery);
      sendMetrics({ provider, userId });
      const params = new URLSearchParams(searchParams.toString());
      params.delete(ACCESS_TOKEN_KEY);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      router.replace(newUrl);
    }
    setSkip(false);
  }, [accessTokenFromQuery, router, searchParams, updateTokenByAuthType, sendMetrics]);

  useMount(() => {
    updateToken();
  });

  useAuthSPProviderInitialzer(skip);
  useAuthSecretKeeperInitializer(skip);

  return skip;
};