import * as Types from '../types';

import { PageDataDtoFragmentFragmentDoc } from './providers';
import { api } from '../../connectors/sp';
export type EventsQueryVariables = Types.Exact<{
  pagination: Types.ConnectionArgs;
  filter?: Types.InputMaybe<Types.EventDataFilter>;
}>;


export type EventsQuery = { __typename?: 'Query', result: { __typename?: 'ListEventResponse', pageData?: { __typename?: 'PageDataDto', count: number, limit: number, offset: number } | null, page: { __typename?: 'EventConnection', edges?: Array<{ __typename?: 'EventEdge', cursor?: string | null, node?: { __typename?: 'Event', _id: string, consumer?: string | null, contract: string, createdAt: any, externalId?: string | null, from?: string | null, name: string, offerId?: string | null, orderId: string, orderStatus?: string | null, owner?: string | null, spender?: string | null, to?: string | null, transactionBlockNumber: number, transactionHash: string, transactionTimestamp: number, value?: string | null, change?: string | null, deposit?: string | null, profit?: string | null } | null }> | null, pageInfo?: { __typename?: 'EventPageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } } };


export const EventsDocument = `
    query Events($pagination: ConnectionArgs!, $filter: EventDataFilter) {
  result: events(pagination: $pagination, filter: $filter) {
    pageData {
      ...PageDataDtoFragment
    }
    page {
      edges {
        cursor
        node {
          _id
          consumer
          contract
          createdAt
          externalId
          from
          name
          offerId
          orderId
          orderStatus
          owner
          spender
          to
          transactionBlockNumber
          transactionHash
          transactionTimestamp
          value
          change
          deposit
          profit
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
}
    ${PageDataDtoFragmentFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Events: build.query<EventsQuery, EventsQueryVariables>({
      query: (variables) => ({ document: EventsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useEventsQuery, useLazyEventsQuery } = injectedRtkApi;

