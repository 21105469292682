import { useEffect } from 'react';
import { useToast } from 'uikit/Toast/hooks/useToast';
import { removeNotification, notificationsSelector } from 'lib/features/notifications';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';

export const useNotifications = () => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(notificationsSelector);
  const { success, error } = useToast({ theme });

  useEffect(() => {
    const { notification, type } = notifications || {};
    if (notification) {
      if (type === 'success') {
        success(notification);
      } else {
        error(notification);
      }
      dispatch(removeNotification());
    }
  }, [error, notifications, success, dispatch]);
};