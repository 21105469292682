import { FC } from 'react';
import {
  ToastContainer as ToastContainerToastify,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainerProps } from './types';

export const ToastContainer: FC<ToastContainerProps> = () => {
  return (
    <ToastContainerToastify />
  );
};
