import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { trackEvent } from 'lib/features/events/thunks';
import { WalletState } from 'lib/features/wallet/types';
import { AnalyticsOffer } from 'utils/analytics';
import { decryptOrderResult } from './helpers';
import { addSuccessNotification, addErrorNotification } from '../notifications';
import { ReplenishDepositProps, CancelOrderProps, GetResultResult } from './types';

export const getResult = createAsyncThunk<
GetResultResult,
  { orderId: string, phrase: string, encryptedResult?: string, analyticsOffers?: AnalyticsOffer[], isNeedDownload?: boolean },
  { rejectValue: string, dispatch: Dispatch<any> }
>(
  'orderDetails/getResult',
  async ({
    orderId, phrase, encryptedResult, analyticsOffers, isNeedDownload,
  }, { rejectWithValue, dispatch }) => {
    try {
      const { isFile, content, download } = await decryptOrderResult(orderId, phrase, encryptedResult);
      if (isFile && isNeedDownload) {
        await download?.();
        dispatch(addSuccessNotification('File downloaded successfully'));
      }
      dispatch(trackEvent({
        eventType: 'order_result_download',
        property: { result: 'success', order: { orderId, offers: analyticsOffers } },
      }));
      return {
        isFile, content, orderId,
      };
    } catch (err) {
      const message = (err as Error)?.message;
      dispatch(addErrorNotification(message));
      dispatch(trackEvent({
        eventType: 'order_result_download',
        property: {
          result: 'error',
          order: { orderId, offers: analyticsOffers },
          error: message,
          errorStack: (err as Error)?.stack,
        },
      }));
      return rejectWithValue(message);
    }
  },
);

export const replenishDeposit = createAsyncThunk<
string,
ReplenishDepositProps,
{ rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
  >(
    'orderDetails/replenishDeposit',
    async ({ executor, orderId }, { rejectWithValue, dispatch }) => {
      try {
        await executor();
        dispatch(addSuccessNotification('Order balance will be replenished in 1-2 minutes'));
        dispatch(trackEvent({
          eventType: 'replenish_deposit',
          property: { result: 'success', order: { orderId } },
        }));
        return orderId;
      } catch (err: any) {
        const { message, stack } = (err as Error) || {};
        dispatch(addErrorNotification(message));
        dispatch(trackEvent({
          eventType: 'replenish_deposit',
          property: {
            result: 'error',
            order: { orderId },
            error: message,
            errorStack: stack,
          },
        }));
        return rejectWithValue((err as Error)?.message);
      }
    },
  );

export const cancelOrder = createAsyncThunk<
string,
CancelOrderProps,
{ rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'orderDetails/cancelOrder',
  async ({ executor, orderId }, { rejectWithValue, dispatch }) => {
    try {
      await executor();
      dispatch(addSuccessNotification('Order cancellation process started'));
      return orderId;
    } catch (err: any) {
      dispatch(addErrorNotification((err as Error)?.message));
      return rejectWithValue((err as Error)?.message);
    }
  },
);
