import { useCallback } from 'react';
import useWalletInitializer from 'lib/features/wallet/hooks/useWalletInitializer';
import { useAuth } from 'hooks/auth/useAuth';
import { useAppSelector } from 'lib/hooks';
import { useUpdateMeQuery } from 'lib/features/secretKeeper/api';
import { useNotification } from 'hooks/useNotification';
import {
  accessTokenSelector, providerSelector,
} from 'lib/features/secretKeeper';
import { WalletType } from 'types/wallet';
import { Providers } from 'lib/features/secretKeeper/types';

const getProviderByWalletType = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.MetaMask:
      return Providers.metamask;
    default:
      return null;
  }
};

export const useAuthSecretKeeperInitializer = (skipProp?: boolean) => {
  const { showError } = useNotification();
  const { login, isProviderSecretKeeper } = useAuth();
  const skip = skipProp || !isProviderSecretKeeper;
  const accessToken = useAppSelector(accessTokenSelector);
  const provider = useAppSelector(providerSelector);
  const userResponse = useUpdateMeQuery(null, { skip: !accessToken || skip });
  const hasUser = !!userResponse?.data;

  const connectWallet = useCallback((walletType: WalletType) => {
    const provider = getProviderByWalletType(walletType);
    if (provider) login(provider).catch(showError);
  }, [login, showError]);

  useWalletInitializer({
    skip: skip || !hasUser || (!provider || ![Providers.metamask].includes(provider)),
    connectWallet,
  });
};