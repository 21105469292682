import { useEffect } from 'react';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';

export const setBodyTheme = (theme: Theme) => {
  const body = document?.querySelector('body');
  if (body) {
    const removeClass = theme === Theme.dark ? Theme.light : Theme.dark;
    body.classList.remove(removeClass);
    body.classList.add(theme);
    body.setAttribute('data-theme', theme);
  }
};

export const useThemeInitializer = () => {
  const theme = useAppSelector(themeSelector);
  useEffect(() => {
    setBodyTheme(theme);
  }, [theme]);
};