import { createMigrate } from 'redux-persist';
import { getInitialStateMethods } from './helpers';

const migrations = {
  0: (state) => ({
    ...state,
    ...getInitialStateMethods(),
  }),
};

export default createMigrate(migrations);
