import { getBaseQuery } from 'connectors/sp';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  OrdersDocument,
  OrdersQuery,
  OrdersQueryVariables,
  SubOrdersDocument,
  SubOrdersQuery,
  SubOrdersQueryVariables,
  OrderDocument,
  OrderQuery,
  OrderQueryVariables,
  OrdersCountQuery,
  OrdersCountQueryVariables,
  OrdersCountDocument,
} from 'generated/schemas/orders';

export const orders = createApi({
  reducerPath: 'orders',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getOrders: build.query<OrdersQuery, OrdersQueryVariables>({
      query: (variables) => ({ document: OrdersDocument, variables }),
    }),
    getSubOrders: build.query<SubOrdersQuery, SubOrdersQueryVariables>({
      query: (variables) => ({ document: SubOrdersDocument, variables }),
    }),
    getOrder: build.query<OrderQuery, OrderQueryVariables>({
      query: (variables) => ({ document: OrderDocument, variables }),
    }),
    getOrdersCount: build.query<OrdersCountQuery, OrdersCountQueryVariables>({
      query: (variables) => ({ document: OrdersCountDocument, variables }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useGetSubOrdersQuery,
  useLazyGetSubOrdersQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useLazyGetOrdersCountQuery,
  useGetOrdersCountQuery,
} = orders;
