import {
  useCallback, useMemo, useEffect, useRef,
} from 'react';
import cn from 'classnames';
import {
  toast,
  ToastContent,
  ToastOptions,
  Id,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { SquareButton } from 'uikit/Buttons/SquareButton';
import { ToastBody } from '../ToastBody';
import classes from '../Toast.module.scss';

export type Success<TData> = (content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id;
export type Error<TData> = (content: ToastContent<TData>, options?: ToastOptions<{}> | undefined) => Id

export interface ToastResult<TData> {
  success: Success<TData>;
  error: Error<TData>;
}

export const getCommonProps = (props: { theme: Theme, autoClose?: number | false }) => {
  const { theme, autoClose } = props || {};
  return {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: cn(classes[theme], classes.toastCommon),
    progressClassName: cn(classes[theme], classes.progress),
    autoClose,
  };
};

export function useToast<TData>(props: { theme: Theme }): ToastResult<TData> {
  const { theme } = useMemo(() => (props || {}), [props]);
  const toastId = useRef<Id[]>([]);
  const onClickCloseButton = useCallback((id: Id) => {
    toastId.current = toastId.current.filter((el) => el !== id);
  }, []);
  const closeButton = useCallback((onClose: (id: Id) => void, id: Id) => {
    return (
      <SquareButton
        theme={theme}
        variant={theme === Theme.dark ? 'black' : 'white'}
        rounded
        className={classes.btnClose}
        onClick={() => onClose(id)}
      >
        <Icon name="close" width={14} />
      </SquareButton>
    );
  }, [theme]);
  const errorIcon = useMemo(() => (
    <Box
      className={cn(classes[theme], classes.iconWrap, classes.iconError)}
      alignItems="center"
      justifyContent="center"
    >
      <Icon
        name="close_small"
        width={10}
        className={classes.icon}
      />
    </Box>
  ), [theme]);
  const successIcon = useMemo(() => (
    <Box className={cn(classes[theme], classes.iconWrap, classes.iconSuccess)} alignItems="center" justifyContent="center">
      <Icon
        name="check_small"
        width={10}
        height={12}
        className={classes.icon}
      />
    </Box>
  ), [theme]);
  const success: Success<TData> = useCallback((content, options) => {
    const id = toast.success(
      (props) => <ToastBody<any> type="success" theme={theme} content={content} testId="toast-success" {...props} />,
      {
        ...getCommonProps({ theme }),
        icon: successIcon,
        closeButton: () => closeButton(onClickCloseButton, id),
        onClose: () => onClickCloseButton(id),
        ...options,
      },
    );
    toastId.current.push(id);
    return id;
  }, [theme, closeButton, onClickCloseButton, successIcon]);
  const error: Error<TData> = useCallback((content, options) => {
    const id = toast.error(
      (props) => <ToastBody<any> type="error" theme={theme} content={content} testId="toast-error" {...props} />,
      {
        ...getCommonProps({ theme, autoClose: false }),
        icon: errorIcon,
        closeButton: () => closeButton(onClickCloseButton, id),
        ...options,
      },
    );
    toastId.current.push(id);
    return id;
  }, [theme, closeButton, errorIcon, onClickCloseButton]);
  useEffect(() => {
    if (toastId.current.length) {
      toastId.current.forEach((item) => {
        if (toast.isActive(item)) {
          toast.update(item, {
            className: cn(classes[theme], classes.toastCommon),
            progressClassName: cn(classes[theme], classes.progress),
            closeButton: () => closeButton(onClickCloseButton, item),
          });
        }
      });
    }
  }, [theme, closeButton, onClickCloseButton]);
  return {
    success,
    error,
  };
}