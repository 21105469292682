import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export interface Storage {
  getItem(key: string, ...args: Array<any>): any;
  setItem(key: string, value: any, ...args: Array<any>): any;
  removeItem(key: string, ...args: Array<any>): any;
}

export enum Storages {
  localStorage = 'localStorage',
  cookie = 'cookie',
  sessionStorage = 'sessionStorage',
}

export interface GetRTKErrorMessageProps {
  path?: string;
  skipStatuses?: number[];
}

export interface RTKResponse<T> {
  data?: T;
  error?: FetchBaseQueryError | SerializedError;
}