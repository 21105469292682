import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions } from 'lib/features/helpers';
import { getMinDeposit, refillMatic, refillTEE } from './thunks';
import { SdkState } from './types';

const initialState: SdkState = {
  minDeposit: '0',
  isConnected: false,
  refillMaticLoading: false,
  refillTeeLoading: false,
};

export const sdk = createSlice({
  name: 'sdk',
  initialState,
  reducers: {
    updateIsConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMinDeposit.fulfilled, (state, action: PayloadAction<string>) => {
        state.minDeposit = action.payload;
      })
      .addCase(refillMatic.pending, (state) => {
        state.refillMaticLoading = true;
      })
      .addCase(refillMatic.rejected, (state) => {
        state.refillMaticLoading = false;
      })
      .addCase(refillMatic.fulfilled, (state) => {
        state.refillMaticLoading = false;
      })
      .addCase(refillTEE.pending, (state) => {
        state.refillTeeLoading = true;
      })
      .addCase(refillTEE.rejected, (state) => {
        state.refillTeeLoading = false;
      })
      .addCase(refillTEE.fulfilled, (state) => {
        state.refillTeeLoading = false;
      });
  },
  selectors: {
    minDepositSelector: (state) => state.minDeposit,
    isConnectedSelector: (state) => state.isConnected,
    refillMaticLoadingSelector: (state) => state.refillMaticLoading,
    refillTeeLoadingSelector: (state) => state.refillTeeLoading,
  },
});

export const { updateIsConnected } = sdk.actions;

export const {
  minDepositSelector,
  isConnectedSelector,
  refillMaticLoadingSelector,
  refillTeeLoadingSelector,
} = sdk.selectors;

export const getBroatcastWhiteList = () => getBroatcastActions(sdk);