import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { parseBackErrorMessage } from 'utils';
import { addSuccessNotification, addErrorNotification } from 'lib/features/notifications';
import { trackEvent } from 'lib/features/events/thunks';
import { WalletState } from 'lib/features/wallet/types';
import BlockchainConnector from 'connectors/sdk/BlockchainConnector';
import { getErrorNotification, successNotification } from './helpers';

export const getMinDeposit = createAsyncThunk<
string,
  undefined,
  { rejectValue: string | Error }
>(
  'sdk/getMinDeposit',
  async (_, { rejectWithValue }) => {
    try {
      return BlockchainConnector.getInstance().getOrderMinDeposit();
    } catch (err: any) {
      return rejectWithValue((err as Error)?.message);
    }
  },
);

export const refillTEE = createAsyncThunk<
void,
  { transfer },
  { rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'sdk/refillTEE',
  async ({ transfer }, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const { selectedAddress } = state.wallet;
    if (!selectedAddress) return;
    try {
      const response = await transfer();
      if ((response as { error: Error })?.error) {
        const message = parseBackErrorMessage(response.error?.message || '');
        throw new Error(message || getErrorNotification('TEE'));
      }
      dispatch(addSuccessNotification(successNotification));
      dispatch(trackEvent({ eventType: 'get_tee', property: { result: 'success' } }));
    } catch (e) {
      const message = (e as Error)?.message;
      dispatch(addErrorNotification(message));
      dispatch(trackEvent({
        eventType: 'get_tee',
        property: { result: 'error', error: message, errorStack: (e as Error)?.stack },
      }));
      return rejectWithValue(message);
    }
  },
);

interface RefillMaticProp {
  transfer: () => Promise<any>;
}

export const refillMatic = createAsyncThunk<
void,
  RefillMaticProp,
  { rejectValue: string | Error, state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'sdk/refillMatic',
  async ({ transfer }, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const { selectedAddress } = state.wallet;
    if (!selectedAddress) return;
    try {
      const response = await transfer();
      if ((response as { error: Error })?.error) {
        const message = parseBackErrorMessage(response.error?.message || '');
        throw new Error(message || getErrorNotification('Matic'));
      }
      dispatch(addSuccessNotification(successNotification));
      dispatch(trackEvent({ eventType: 'get_matic', property: { result: 'success' } }));
    } catch (e) {
      const message = (e as Error)?.message;
      dispatch(addErrorNotification(message));
      dispatch(trackEvent({
        eventType: 'get_matic',
        property: { result: 'error', error: message, errorStack: (e as Error)?.stack },
      }));
      return rejectWithValue(message);
    }
  },
);
