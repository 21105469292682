import { DisplayColumnDef } from '@tanstack/react-table';
import { Node } from 'types/pages';
import { getMyContentColumns, TABLE_NAME as TABLE_NAME_MY_CONTENT } from 'components/MyFiles/TableContent/helpers';
import { Column } from './types';
import { getMyOrdersColumns, TABLE_NAME as TABLE_NAME_MY_ORDERS } from 'components/MyOrders/helpers';
import { getColumns as getTunnelOrdersColumns, TABLE_NAME as TABLE_NAME_TUNNEL_ORDERS } from 'components/Order/TunnelOrders/helpers';
import { getColumns as getEventsColumns, TABLE_NAME as TABLE_NAME_EVENTS } from 'components/Order/Events/helpers';

const getInitialStateTemplate = (hidenColumns: string[], func: () => Record<string, any>) => {
  return Object.values(func()).reduce((acc: Column[], item: DisplayColumnDef<Node>) => {
    const { id, header: label, meta } = item;
    return !meta?.show ? [
      ...acc,
      {
        id: id || '',
        label: (label || '') as string,
        checked: !hidenColumns.includes(id as string),
      },
    ] : acc;
  }, []);
};

const getInitialStateMyContent = () => {
  const hidenColumns = ['id'];
  return getInitialStateTemplate(hidenColumns, getMyContentColumns);
};

const getInitialStateMyOrders = () => {
  const hidenColumns = ['createdDate', 'dataset'];
  return getInitialStateTemplate(hidenColumns, getMyOrdersColumns);
};

const getInitialStateOrderTunnels = () => {
  const hidenColumns = ['model', 'dataset'];
  return getInitialStateTemplate(hidenColumns, getTunnelOrdersColumns);
};

const getInitialStateEvents = () => {
  return getInitialStateTemplate([], getEventsColumns);
};

export const getInitialState = () => {
  return {
    columns: {
      [TABLE_NAME_MY_CONTENT]: getInitialStateMyContent(),
      [TABLE_NAME_MY_ORDERS]: getInitialStateMyOrders(),
      [TABLE_NAME_TUNNEL_ORDERS]: getInitialStateOrderTunnels(),
      [TABLE_NAME_EVENTS]: getInitialStateEvents(),
    },
  };
};
