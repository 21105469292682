import { downloadFileBlob } from 'utils/blob/blob';

export const streamToBuffer = async (stream: ReadableStream): Promise<Buffer> => {
  const chunks: Buffer[] = [];
  let done = false;
  const reader = stream.getReader();

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    if (value) {
      chunks.push(value);
    }
    done = readerDone;
  }

  return Buffer.concat(chunks);
};

export const streamToBlob = async (stream: ReadableStream, contentType: string): Promise<Blob> => {
  const chunks: Buffer[] = [];
  let done = false;
  const reader = stream.getReader();

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    if (value) {
      chunks.push(value);
    }
    done = readerDone;
  }

  return new Blob(chunks, { type: contentType });
};

export const streamToString = async (stream: ReadableStream, format: BufferEncoding = 'base64') => {
  return (await streamToBuffer(stream)).toString(format);
};

export const downloadFileStream = async (stream: ReadableStream, fileName: string, contentType: string) => {
  if (!(window as any)?.showSaveFilePicker) {
    return downloadFileBlob(await streamToBlob(stream, contentType), fileName);
  }
  const fileHandle = await (window as any).showSaveFilePicker({
    suggestedName: fileName,
  });

  const writableStream = await fileHandle.createWritable();
  const reader = stream.getReader();

  let done = false;
  while (!done) {
    const { value, done: readerDone } = await reader.read();
    if (value) {
      await writableStream.write(value);
    }
    done = readerDone;
  }

  await writableStream.close();
};