'use client';

import { FC } from 'react';
import { ToastContainer } from 'uikit/Toast/ToastContainer';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { useAuthInitializer } from 'hooks/auth/useAuthInitializer';
import { useWindowSize } from 'hooks/useWindowSize';
import { useNotifications } from 'hooks/useNotifications';
import { useThemeInitializer } from 'hooks/useThemeInitializer';
import { useMockHTTP } from 'hooks/useMockHTTP';
import { AppWrapProps } from './types';

export const AppWrap: FC<AppWrapProps> = ({ children }) => {
  const theme = useAppSelector(themeSelector);
  useWindowSize();
  useNotifications();
  useAuthInitializer();
  useThemeInitializer();
  const waitUntilMock = useMockHTTP();

  if (waitUntilMock) return null;

  return (
    <>
      {children}
      <ToastContainer theme={theme} />
    </>
  );
};