import { createLinkAndDownload, downloadFileBlob } from 'utils/blob/blob';
import { downloadFileStream } from 'utils/stream';

export const downloadFileString = (data: string | Buffer, fileName: string, type = 'application/json') => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  createLinkAndDownload(url, fileName);
};

export const downloadFile = async (
  data: Blob | string | ReadableStream | Buffer,
  fileName: string,
  type = 'application/json',
) => {
  if (data instanceof Blob) {
    downloadFileBlob(data, fileName);
  } else if (data instanceof ReadableStream) {
    await downloadFileStream(data, fileName, type);
  } else {
    downloadFileString(data, fileName, type);
  }
};
